import {
  h
} from 'preact';


export default () => {
  return <div style={{
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    top: '50%',
    left: '50%',
  }}>
    <div
      className="booking-congrats"
    >
      <div
        className="title-holder"
      >
        <div
        
        >
          <h3 className={'heading'} style={{
            fontSize: '32px',
            lineHeight: '100%',
            background: "url('/assets/images/icon-bg-star.svg') no-repeat 12% 26px",
          }}>
            
            <div style={{
              background: "url('/assets/images/icon-star.svg') no-repeat 100% -2px",
              paddingTop: '10px'
            }}><mark>Thank you</mark> for</div> submitting your<br/>
            <div
              style={{
                background: "url('/assets/images/icon-title-decoration-1.svg') no-repeat 24px 3px",
              }}
            >
              <div style={{
                background: "url('/assets/images/icon-title-decoration-2.svg') no-repeat 90% 3px",
              }}>responses</div>
            </div>
          </h3>
        </div>
      
      </div>
    </div>
    <div
      style={{
        marginTop: '20px',
        textAlign: 'center'
      }}
    >
      <p
        style={{
          font: '18px Poppins, san-serif',
          color: '#697786',
          lineHeight: '150%',
        }}
      >Tap the back arrow at the top to<br /> return to "My Rewards".
      </p>
    </div>
  </div>
}