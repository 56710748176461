import {h} from 'preact';
import 'preact/debug'
import Header from "./header";
import Main from "./main-home";
import Footer from "./footer";
import {HomeContext} from "../context/HomeContext";
import {useContext, useEffect, useState} from 'preact/hooks';
import MenuPopup from "./popup/MenuPopup";
import SearchPopup from "./popup/SearchPopup";
import HairServicePopup from "./popup/HairServicePopup";
import OtherServicesPopup from "./popup/OtherServicesPopup";
import CountriesPopup from "./popup/CountriesPopup";
import CitiesPopup from "./popup/CitiesPopup";
import AreasPopup from "./popup/AreasPopup";
import Router, {route} from "preact-router";
import MainSearchResults from "./main-search-results";
import SalonInfo from "./sections/SalonInfo";
import BlogSection from "./sections/BlogSection";
import ArticlePage from "./sections/ArticlePage";
import FindStylistPopup from "./popup/FindStylistPopup";
import Helmet from "preact-helmet";
import PrivacyPolicy from "./sections/PrivacyPolicy";
import Terms from "./sections/Terms";
import {scrollToTop} from "../utils/functions";
import ForgotYourPassword from "./sections/ForgotYourPassword";
import PasswordPopup from "./popup/PasswordPopup";
import ResetPassword from "./sections/ResetPassword";
import PasswordResetPopup from "./popup/PasswordResetPopup";
import SurveySuccess from "./sections/SurveySuccess";

const App = () => {
  
  const context = useContext(HomeContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisibleScrollButton, setIsVisibleScrollButton] = useState(false)
  const [email, setEmail] = useState('')
  
  const handleScroll = () => {
    
    const position = window.pageYOffset;
    if (scrollPosition > position) {
      setIsVisibleScrollButton(true);
    }
    setScrollPosition(prevState => {
      if (prevState > position && position > 0) {
        setIsVisibleScrollButton(true);
      } else {
        setIsVisibleScrollButton(false);
      }
      return position
    });
  };
  const [showHeader, setShowHeader] = useState(true)
  const [showFooter, setShowFooter] = useState(true)
  
  useEffect(() => {
    if (window.location.href.includes('survey-success')) {
      return ;
    }
    const queryParams = new URLSearchParams(window.location.search)
    const userId = queryParams.get("user_id")
    if (userId) {
      route(`/stylists/${userId}`);
    }
    window.addEventListener('scroll', handleScroll, {passive: true});
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    
    <div className={`${context.openedPopup ? 'popup-visible' : ''}`}>
      <Helmet
        title={"Mirron - Enhance your beauty for African | Tips for Glowing Skin, Hair & Makeup"}
        meta={[
          {
            name: "description",
            content: "Mirron is designed just to enhance your beauty and stand out. We support every modern woman to be beautiful, confident and enjoy life to especially African. We provide beauty tips for glowing skin, hairstyles, makeup ideas and much more for you. Visit now!"
          },
          {name: "keywords", content: "Mirron, beauty tips for glowing skin, tips for glowing skin"}
        ]}
      />
      
      <div id="wrapper">
        {showHeader && <Header/>}
        <main id="main">
          <Router default={"/"} onChange={(e) => {
              console.log('DARUDA', e.current.props?.path, e.current.props?.path === '/survey-success')
            const isNotShown = e.current.props?.matches?.from_app || e.current.props?.path === '/password/reset' || e.current.props?.path === '/survey-success'
            setShowHeader(!e.current.props?.matches?.from_app && e.current.props?.path !== '/survey-success' );
            setShowFooter(!isNotShown);
            if (e.current.props?.matches?.email) {
              setEmail(e.current.props?.matches?.email)
            }
            scrollToTop()
          }}>
            
            <Main path="/"/>
            <SalonInfo path={'/stylists/:id'}/>
            <MainSearchResults path={"/stylists"}/>
            <BlogSection path={'/articles/category/:slug'}/>
            <ArticlePage path={'/articles/:slug'}/>
            <BlogSection path={'/articles'}/>
            <PrivacyPolicy path={'/privacy_policy'}/>
            <Terms path={'/terms'}/>
            <SurveySuccess path={'/survey-success'}/>
            <ResetPassword path={'/password/reset/:token'} email={email}/>
            <ForgotYourPassword path={'/password/reset'}/>
          </Router>
        </main>
        {showFooter && <Footer/>}
        <div className={'fader'} onClick={context.closePopup}/>
        
        <MenuPopup open={context.popupName === 'menu'} onClose={context.closePopup} onFind={() => {
          context.openPopup('search')
        }}/>
        <SearchPopup open={context.popupName === 'search'} onClose={context.closePopup}
                     value={context.searchText}
                     onChange={context.setSearch}/>
        
        
        <HairServicePopup open={context.popupName === 'hair-services'} onClose={context.closePopup}
                          onSelect={context.setService}/>
        <OtherServicesPopup open={context.popupName === 'others-services'} onClose={context.closePopup}
                            onSelect={context.setService}/>
        <CountriesPopup
          open={context.popupName === 'countries'}
          onClose={context.closePopup}
          onSelect={context.setCountry}
          items={context.locations}
          onBack={context.closePopup}
        />
        <CitiesPopup
          open={context.popupName === 'cities'}
          onClose={context.closePopup}
          onSelect={context.setCity}
          items={context.country?.cities || []}
          onBack={() => context.openPopup('countries')}
        />
        <AreasPopup
          onBack={() => context.openPopup('cities')}
          open={context.popupName === 'areas'}
          onClose={context.closePopup}
          onSelect={context.setArea}
          items={context.city?.areas || []}
        />
        <FindStylistPopup
          onBack={context.closePopup}
          open={context.popupName === 'find-stylist'}
          onClose={context.closePopup}
        />
        <PasswordPopup
          onBack={context.closePopup}
          open={context.popupName === 'forgot-password-request-success'}
          onClose={context.closePopup}
        />
        <PasswordResetPopup
          onBack={context.closePopup}
          open={context.popupName === 'password-reset-success'}
          onClose={context.closePopup}
        />
        
        
        <button onClick={scrollToTop} className={`btn-scroll-up ${isVisibleScrollButton ? 'visible' : ''}`}><img
          src="/assets/images/icon-arrow-up.svg" alt="Up"/></button>
      </div>
    </div>
  )
}

export default App;
